.container {
  align-items: center;
  background: url('../../assets/loginPage/olympiads-logos.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  @media (max-width: 600px) {
    background: #F4F7FF;
  }

  .logo {
    margin-bottom: 56px;
  }

  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    background: #6025E1;
    border-radius: 32px;
    max-width: 445px;
    padding: 62px 65px 84px;

    @media (max-width: 600px) {
      padding: 75px 30px;
      margin-inline: 12px;
    }
  }


  .title {
    @media (max-width: 600px) {
      font-size: 32px;
    }

    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 32px;
  }

  .text {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 48px;
  }

}